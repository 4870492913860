import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../Sidenav/Sidenav';
import styles from './AccountDetailsMobile.module.css';
import { ReactComponent as CancelledChequeIcon } from '../../assets/accountDetails/CancelledChequeIcon.svg';
import { ReactComponent as SmartsaHatColorIcon } from '../../assets/DashboardIcons/SmartsaHatColorIcon.svg';
import { ReactComponent as NavBarHamburgerIcon } from '../../assets/DashboardIcons/NavBarHamburgerIcon.svg';
import { Link } from 'react-router-dom';

const AccountDetailsMobile = () => {
  const [isDataFilled, setIsDataFilled] = useState(true);
  const [isEditBtnClicked, setIsEditBtnClicked] = useState(false);
  const [formData, setFormData] = useState({
    bank_name: '',
    micr_code: '',
    branch_name: '',
    ifsc_code: '',
    account_name: '',
    address: '',
    acct_num: '',
    pan_num: ''
  });
  const institute_id = localStorage.getItem('institute_id');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const resFormData = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/accountdetails/${institute_id}`);
        setFormData(resFormData.data[0]);
        console.log(resFormData.data, " Got form DATA");
      } catch (error) {
        setFormData({});
        setIsDataFilled(false);
        console.log({ Error: 'No such data exist' });
      }
    }
    fetchData();
  }, []);
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  const handleChangeAccountDetails = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
  const handleSubmit = async (e = null) => {
    if (e !== undefined && e !== null) {

      e.preventDefault();
    }
    console.log(formData);
    try {
      console.log(formData, " frontend getting data");
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/accountdetails/${institute_id}`, formData);
      if (res.status == 200) {
        toast.success('Data Saved Successfully');
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong!');
    }
  }
  const handleOnSaveBtnClick = () => {
    handleSubmit();
    setIsEditBtnClicked(prev => !prev);
  }
  return (
    <>
      <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} setLogoutDialog={setLogoutDialog} />
      <div className={styles.navbarHamburger} onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
        <NavBarHamburgerIcon className={styles.svgIcon} />
      </div>
      <div className={styles.heading}>Account Details</div>
      <ToastContainer />
      <div className={styles.container}>
        <form className={styles.detailsGrid} onSubmit={handleSubmit}>
          <div className={styles.smFieldContainer}>
            <div>
              {isEditBtnClicked && (<span className={styles.inputLabel}>Bank Name</span>)}
              <input type="text" placeholder='Bank Name' name='bank_name' value={formData.bank_name} onChange={(isEditBtnClicked || !isDataFilled) ? handleChangeAccountDetails : undefined} />
            </div>
            <div>
              {isEditBtnClicked && (<span className={styles.inputLabel}>MICR Code</span>)}
              <input type="text" placeholder='MICR Code' name='micr_code' value={formData.micr_code} onChange={(isEditBtnClicked || !isDataFilled) ? handleChangeAccountDetails : undefined} />
            </div>
          </div>
          <div className={styles.smFieldContainer}>
            <div>
              {isEditBtnClicked && (<span className={styles.inputLabel}>Branch Name</span>)}
              <input type="text" placeholder='Branch Name' name='branch_name' value={formData.branch_name} onChange={(isEditBtnClicked || !isDataFilled) ? handleChangeAccountDetails : undefined} />
            </div>
            <div>
              {isEditBtnClicked && (<span className={styles.inputLabel}>IFSC Code</span>)}
              <input type="text" placeholder='IFSC Code' name='ifsc_code' value={formData.ifsc_code} onChange={(isEditBtnClicked || !isDataFilled) ? handleChangeAccountDetails : undefined} />
            </div>
          </div>
          {isEditBtnClicked && (<span className={styles.inputLabel}>Address</span>)}
          <input type="text" placeholder='Address' name='address' value={formData.address} onChange={(isEditBtnClicked || !isDataFilled) ? handleChangeAccountDetails : undefined} />
          {isEditBtnClicked && (<span className={styles.inputLabel}>PAN Number</span>)}
          <input type="text" placeholder='PAN Number' name='pan_num' value={formData.pan_num} onChange={(isEditBtnClicked || !isDataFilled) ? handleChangeAccountDetails : undefined} />
          {isEditBtnClicked && (<span className={styles.inputLabel}>Account Name</span>)}
          <input type="text" placeholder='Account Name' name='account_name' value={formData.account_name} onChange={(isEditBtnClicked || !isDataFilled) ? handleChangeAccountDetails : undefined} />
          {isEditBtnClicked && (<span className={styles.inputLabel}>Account Number</span>)}
          <input type="text" placeholder='Account Number' name='acct_num' value={formData.acct_num} onChange={(isEditBtnClicked || !isDataFilled) ? handleChangeAccountDetails : undefined} />
          <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '4px', cursor: 'pointer', fontSize: '10px', alignItems: 'center' }}>
            <CancelledChequeIcon />
            <div>Upload Cancelled Cheque (To change account number)</div>
          </div>
          {!isDataFilled && <div className={styles.buttonContainer}>
            <button type='submit' className={styles.buttonContainerBtn}
            >Submit</button>
          </div>}
        </form>
        {
          (isDataFilled && !isEditBtnClicked) &&
          (<div className={styles.buttonContainer}>
            <button type='submit' className={styles.buttonContainerBtn}
              onClick={() => (setIsEditBtnClicked(prev => !prev))}
            >Edit</button>
          </div>)
        }
        {
          isEditBtnClicked &&
          (
            <div className={styles.buttonContainer}>
              <button type='submit' className={styles.buttonContainerBtn1} style={{ background: 'none', color: 'black', border: '0.13rem solid #313C63' }}
                onClick={() => (setIsEditBtnClicked(prev => !prev))}
              >Cancel</button>
              <button type='submit' className={styles.buttonContainerBtn}
                onClick={handleOnSaveBtnClick}
              >Save</button>
            </div>
          )
        }
      </div>
      <div className={styles.navbarBottom}>
        <Link to={'/dashboard'}>
          <SmartsaHatColorIcon fill='#828282' className={styles.smartsaHatIcon} />
        </Link>
      </div>
    </>
  )
}

export default AccountDetailsMobile