import React, { useEffect, useState } from 'react';
import styles from './SidenavRightMobile.module.css';
import { ReactComponent as SearchIcon } from '../../../assets/DashboardIcons/searchicon.svg';
import { ReactComponent as FilterIcon } from '../../../assets/DashboardIcons/FilterIcon.svg';
import { ReactComponent as DropDownIcon } from '../../../assets/DashboardIcons/DropDownIcon.svg';
import { ReactComponent as DropUpIcon } from '../../../assets/DashboardIcons/DropUpIcon.svg';
import { Link } from 'react-router-dom';
import { Divider, TextField, IconButton } from '@mui/material';

const SidenavRightMobile = ({ setFilterDrawerState = () => { }, studentsList, setStudentsList = () => { } }) => {
  // console.log(studentsList, " inside sideright");
  const [isSubDropDownClick1, setIsSubDropDownClick1] = useState(false);
  const [isSubDropDownClick2, setIsSubDropDownClick2] = useState(false);
  const [isSubDropDownClick3, setIsSubDropDownClick3] = useState(false);
  const [isOverallClick, setIsOverallClick] = useState(false);

  // handle 'Clear All' onClick
  /* const [isClearAllClicked, setIsClearAllClicked] = useState(false);
  const handleClearAll = () => {

  } */

  /* Filters */
  const tags = [];
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [isFilterDisplay, setIsFilterDisplay] = useState(false);
  // console.log(studentsList, "Hello there");
  const handleCheckboxClick = (e) => {
    const { name, value, checked } = e.target;
    // console.log(name, value, checked);
    if (checked) {
      setSelectedItems(prevSelectedItems => [...prevSelectedItems, { parameter: name, value: value }]);
    } else {
      setSelectedItems(prevSelectedItems => prevSelectedItems.filter(item => item.value !== value));
    }
  }
  console.log(selectedItems);
  const handleApplyFilter = () => {
    console.log("Apply Clicked ", selectedItems);
    console.log("studentList ", studentsList);
    setIsFilterDisplay(prev => !prev);
    const selectedValues = selectedItems.map(item => item.value);
    if (searchTerm.length) selectedValues.push(searchTerm.toLowerCase());
    const filtered = studentsList.filter(stud => {
      return selectedValues.every(selectedValue => Object.values(stud).some(para => selectedValue.toLowerCase().includes(String(para).toLowerCase())));
    });
    console.log(filtered, " filtered students");
    let filteredSorted = filtered.sort((a, b) => {
      let x = a.name.toLowerCase(), y = b.name.toLowerCase();
      if (x > y) return 1;
      else if (x < y) return -1;
      return 0;
    });
    setFilteredStudents(filteredSorted);
    setStudentsList(filteredSorted);
    setIsSubDropDownClick1(false);
    setIsSubDropDownClick2(false);
    setIsSubDropDownClick3(false);
  }

  const handleClickSubDropdown1 = () => {
    setFilteredStudents([]);
    setIsSubDropDownClick1(true);
    setIsSubDropDownClick2(false);
    setIsSubDropDownClick3(false);
    setIsOverallClick(false);
  }
  const handleClickSubDropdown2 = () => {
    setFilteredStudents([]);
    setIsSubDropDownClick2(true);
    setIsSubDropDownClick1(false);
    setIsSubDropDownClick3(false);
    setIsOverallClick(false);
  }
  const handleClickSubDropdown3 = () => {
    setFilteredStudents([]);
    setIsSubDropDownClick3(true);
    setIsSubDropDownClick1(false);
    setIsSubDropDownClick2(false);
    setIsOverallClick(false);
  }
  const handleOverallClick = () => {
    setIsOverallClick(true);
  }
    
  const handleOnClearAllBtn = () => {
    setSelectedItems([]);
    setSearchTerm('');
    setStudentsList(studentsList);
    setFilteredStudents([]);
  }
  /* Search Filters */
  const handleSearchInputText = (txt) => {
    setSearchTerm(txt);
    // console.log(searchTerm);
  }
  const handleApplyFilterSearchTerm = () => {
    const filtered = studentsList.filter(stud => {
      return stud.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        stud.status.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setFilteredStudents(filtered);
  }
  useEffect(() => {
    if (searchTerm) handleApplyFilterSearchTerm();
  }, [searchTerm]);
  return (
    <div className={styles.sidenav}>
      <div className={styles.fieldNames}>
        <div className={styles.subDropdown} onClick={handleClickSubDropdown1} style={{ background: isSubDropDownClick1 && '#c5e4ff' }}>
          Class
        </div>
        <Divider />
        <div className={styles.subDropdown} onClick={handleClickSubDropdown2} style={{ background: isSubDropDownClick2 && '#c5e4ff' }}>
          Section
        </div>
        <Divider />
        <div className={styles.subDropdown} onClick={handleClickSubDropdown3} style={{ background: isSubDropDownClick3 && '#c5e4ff' }}>
          Status
        </div>
        <Divider />
        <div className={styles.subDropdown} 
        style={{ background: isOverallClick && '#c5e4ff' }}
        onClick={() => {
          handleOverallClick();
          handleApplyFilter();
          setIsSubDropDownClick1(false);
          setIsSubDropDownClick2(false);
          setIsSubDropDownClick3(false);
        }}>
          Overall
        </div>
        <Divider />
      </div>
      <div className={styles.fieldNameCategories}>
        <div className={styles.inputField}>
          <TextField
            onKeyDown={(e) => { if (e.key === 'Enter') { setStudentsList(filteredStudents); } }}
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => handleSearchInputText(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                  boxShadow: 'none',
                },
              },
            }}
            InputProps={{
              style: {
                height: '1.75rem',
                width: '7rem',
                boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A',
                padding: '0',
              },
              endAdornment: (
                // <InputAdornment position="end">
                <IconButton>
                  <SearchIcon onClick={() => { setStudentsList(filteredStudents); }} />
                </IconButton>
                // </InputAdornment>
              ),
            }}
          />
        </div>
        {/* <div> */}
        {/* List based on selected fields */}
        {
          isSubDropDownClick1 && (
            <div className={styles.dropDownMenuItems}>
              <div>
                <div>8th</div>
                <div><input type="checkbox" name='class' value='8th' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'class' && selectedItem.value === '8th')} onChange={handleCheckboxClick} /></div>
              </div>
              <Divider />
              <div>
                <div>9th</div>
                <div><input type="checkbox" name='class' value='9th' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'class' && selectedItem.value === '9th')} onChange={handleCheckboxClick} /></div>
              </div>
              <Divider />
              <div>
                <div>10th</div>
                <div><input type="checkbox" name='class' value='10th' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'class' && selectedItem.value === '10th')} onChange={handleCheckboxClick} /></div>
              </div>
              <Divider />
              <div>
                <div>11th</div>
                <div><input type="checkbox" name='class' value='11th' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'class' && selectedItem.value === '11th')} onChange={handleCheckboxClick} /></div>
              </div>
              <Divider />
              <div>
                <div>12th</div>
                <div><input type="checkbox" name='class' value='12th' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'class' && selectedItem.value === '12th')} onChange={handleCheckboxClick} /></div>
              </div>
              <Divider />
              <div>
                <div>IIT JEE</div>
                <div><input type="checkbox" name='iitjee' value='IIT JEE' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'iitjee' && selectedItem.value === 'IIT JEE')} onChange={handleCheckboxClick} /></div>
              </div>
              <Divider />
              <div>
                <div>Other Courses</div>
                <div><input type="checkbox" name='others' value='Other Courses' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'others' && selectedItem.value === 'Other Courses')} onChange={handleCheckboxClick} /></div>
              </div>
              <Divider />
            </div>
          )
        }
        {
          isSubDropDownClick2 && (
            <div className={styles.dropDownMenuItems}>
              <div>
                <div>A</div>
                <div><input type="checkbox" name='section' value='A' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'section' && selectedItem.value === 'A')} onChange={handleCheckboxClick} /></div>
              </div>
              <Divider />
              <div>
                <div>B</div>
                <div><input type="checkbox" name='section' value='B' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'section' && selectedItem.value === 'B')} onChange={handleCheckboxClick} /></div>
              </div>
              <Divider />
              <div>
                <div>C</div>
                <div><input type="checkbox" name='section' value='C' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'section' && selectedItem.value === 'C')} onChange={handleCheckboxClick} /></div>
              </div>
              <Divider />
            </div>
          )
        }
        {
          isSubDropDownClick3 && (
            <div className={styles.dropDownMenuItems}>
              <div>
                <div><span style={{ width: '0.75rem', height: '0.75rem', borderRadius: '0.375rem', backgroundColor: '#E53D44', marginRight: '4px' }}></span>Critical</div>
                <div><input type="checkbox" name='status' value='Critical' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'status' && selectedItem.value === 'Critical')} onChange={handleCheckboxClick} /></div>
              </div>
              <Divider />
              <div>
                <div><span style={{ width: '0.75rem', height: '0.75rem', borderRadius: '0.375rem', backgroundColor: '#133593', marginRight: '4px' }}></span>Watchful</div>
                <div><input type="checkbox" name='status' value='Watchful' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'status' && selectedItem.value === 'Watchful')} onChange={handleCheckboxClick} /></div>
              </div>
              <Divider />
              <div>
                <div><span style={{ width: '0.75rem', height: '0.75rem', borderRadius: '0.375rem', backgroundColor: '#3C9313', marginRight: '4px' }}></span>Outstanding</div>
                <div><input type="checkbox" name='status' value='Outstanding' checked={selectedItems.some(selectedItem => selectedItem.parameter === 'status' && selectedItem.value === 'Outstanding')} onChange={handleCheckboxClick} /></div>
              </div>
              <Divider />
            </div>
          )
        }
        <div className={styles.dropDownMenuItems} style={{overflowY: 'scroll'}}>
          {
            filteredStudents?.map((stud, idx) => (
              <>
              <Link to={`/studentprofile/${stud.user_id}`} onClick={() => {setFilterDrawerState(false);}} className={styles.filteredItemsStyle}>
              <div style={{ margin: '0 0.6rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', width: '100%' }} key={stud.user_id}>
                {/* Here, Add student_id to get specific student profile page */}
                  <div>{stud.name}</div>
                  <div style={{
                    width: '0.75rem', height: '0.75rem', borderRadius: '0.375rem',
                    backgroundColor: stud.status === 'OutStanding' ? 'rgba(60, 147, 19, 1)' : stud.status === 'Watchful' ? 'rgba(19, 53, 147, 1)' : 'rgba(229, 61, 68, 1)',
                    margin: '3.4px 4px 2.5px 0px'
                  }}></div>
                  </div>
                </Link>
              <Divider />
              </>
            ))
          }
        </div>
        {/* </div> */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', margin: '0.313rem', marginBottom: '2.4rem' }}>
          <button className={styles.btnStyle} style={{ border: '1.39px solid #313C63', flex: '1' }} onClick={() => {setFilterDrawerState(false); handleOnClearAllBtn();}}>Clear All</button>
          <button
            className={styles.btnStyle}
            style={{ background: '#313C63', color: 'white', flex: '1' }}
            onClick={() => { handleApplyFilter() }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  )
}

export default SidenavRightMobile