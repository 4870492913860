import React, { useEffect, useState } from 'react';

import styles from './StudentProfileMobile.module.css';
import Sidebar from '../Sidenav/Sidenav';
import SidenavRightMobile from '../Common/FilterSidenav/SidenavRightMobile';

/* Importing svg file as ReactComponent */
import { ReactComponent as NavBarHamburgerIcon } from '../../assets/DashboardIcons/NavBarHamburgerIcon.svg';
import { ReactComponent as TooltipIcon } from '../../assets/DashboardIcons/TooltipIcun.svg';
import { ReactComponent as FilterIcon } from '../../assets/DashboardIcons/FilterIcon.svg';
import { ReactComponent as NoOfQuestionsIcon } from '../../assets/DashboardIcons/NoOfQuestionsIcon.svg';
import { ReactComponent as AccuracyIcon } from '../../assets/DashboardIcons/AccuracyIcon.svg';
import { ReactComponent as NoOfAttemptsIcon } from '../../assets/DashboardIcons/NoOfAttemptsIcon.svg';
import { ReactComponent as TotalTimeIcon } from '../../assets/DashboardIcons/TotalTimeIcon.svg';
import { ReactComponent as GreaterThanIcon } from '../../assets/DashboardIcons/GreaterThanIcon.svg';
import { ReactComponent as SmartsaHatColorIcon } from '../../assets/DashboardIcons/SmartsaHatColorIcon.svg';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { ClickAwayListener, Tooltip, SwipeableDrawer } from '@mui/material';


const StudentProfileMobile = () => {
  const institute_id = localStorage.getItem('institute_id');
  /* Get the user_id of the specific student */
  const { user_id } = useParams();
  // console.log(user_id, " User ID in Student Profile");
  /* User Details */
  const [userDetails, setUserDetails] = useState({});
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/usersection/${user_id}`)
      .then((response) => {
        setUserDetails(response.data);
        // console.log("World ", response.data);
      })
  }, [user_id]);

  /* Analytics */
  /* Analytics BackEnd */
  const [todayAttemptSummary, setTodayAttemptSummary] = useState([]);
  const [previousAttemptSummary, setPreviousAttemptSummary] = useState([]);
  const [previousAttemptSummary1, setPreviousAttemptSummary1] = useState([]);
  const [previousAttemptSummary2, setPreviousAttemptSummary2] = useState([]);
  const [tooltipOpenIconLastDay, setTooltipOpenIconLastDay] = useState([]); // for table row tooltip (Last Active Day)
  const [tooltipOpenArrowLastDay, setTooltipOpenArrowLastDay] = useState([]); // for table row arrow tooltip (Last Active Day)
  const [tooltipOpenIconToday, setTooltipOpenIconToday] = useState([]); // for table row tooltip (Today)
  const [tooltipOpenArrowToday, setTooltipOpenArrowToday] = useState([]); // for table row arrow tooltip (Today)

  useEffect(() => {
    const fetchData = async () => {
      const resTodayAttemptSummary = await axios(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/todayattemptsummary/${user_id}`);
      const resPreviousAttemptSummary = await axios(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/previousattemptsummary/${user_id}`);
      const resPreviousAttemptSummary2 = await axios(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/previousattemptsummarytooltipbest/${user_id}`);
      const resPreviousAttemptSummary1 = await axios(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/previousattemptsummarytooltiplast/${user_id}`);
      setTodayAttemptSummary(resTodayAttemptSummary.data);
      setPreviousAttemptSummary(resPreviousAttemptSummary.data);
      setPreviousAttemptSummary1(resPreviousAttemptSummary1.data);
      setPreviousAttemptSummary2(resPreviousAttemptSummary2.data);
      const tempBool = new Array(Math.max(resTodayAttemptSummary.data.length, resPreviousAttemptSummary.data.length)).fill(false);
      setTooltipOpenIconLastDay(tempBool);
      setTooltipOpenArrowLastDay(tempBool);
      setTooltipOpenIconToday(tempBool);
      setTooltipOpenArrowToday(tempBool);
    }
    fetchData();
  }, [user_id]);
  const handleLastScore = (id) => {
    const mission = previousAttemptSummary1.find(mission => mission.mission_id === id);
    return mission?.total_correct;
  }
  const handleTotalLastScore = (id) => {
    const mission = previousAttemptSummary1.find(mission => mission.mission_id === id);
    return mission?.total_questions;
  }
  const handleTimeElapsedLastActive = (id) => {
    const mission = previousAttemptSummary1.find(mission => mission.mission_id === id);
    let time = mission?.elapsed_time.split(':');
    return `${time[0]}h ${time[1]}m ${time[2]}s`;
  }
  /* Best in Last Active Tooltip */
  const handleBestScore = (id) => {
    const mission = previousAttemptSummary2.find(mission => mission.mission_id === id);
    // console.log("hello", mission)
    return mission?.max_total_correct;
  }
  const handleTotalBestScore = (id) => {
    const mission = previousAttemptSummary2.find(mission => mission.mission_id === id);
    return mission?.total_questions;
  }
  const handleTimeElapsedBest = (id) => {
    const mission = previousAttemptSummary2.find(mission => mission.mission_id === id);
    let time = mission?.min_elapsed_time.split(':');
    return `${time[0]}h ${time[1]}m ${time[2]}s`;
    // return mission?.min_elapsed_time;
  }
  const handleTimeFormat = (time) => {
    const temp = time?.split(':');
    return `${temp[0]}h ${temp[1]}m ${temp[2]}s`;
  }

  /* Tooltip Cards */
  const [tooltipOpen1, setTooltipOpen1] = useState({ 'card1': false, 'card2': false, 'card3': false, 'card4': false });
  const handleTooltipClose1 = (name) => {
    setTooltipOpen1(prev => {
      const temp = { ...prev };
      temp[name] = false;
      return temp
    });
  };

  const handleTooltipOpen1 = (name) => {
    setTooltipOpen1(prev => {
      const temp = { ...prev };
      temp[name] = true;
      console.log(temp, " got temp")
      return temp;
    });
  };

  const handleTooltipCloseIconLastDay = (idx) => {
    setTooltipOpenIconLastDay(prev => {
      const temp = [...prev];
      temp[idx] = false;
      return temp;
    })
  }
  const handleTooltipOpenIconLastDay = (idx) => {
    setTooltipOpenIconLastDay(prev => {
      const temp = [...prev];
      temp[idx] = true;
      return temp;
    })
  }

  const handleTooltipCloseArrowLastDay = (idx) => {
    setTooltipOpenArrowLastDay(prev => {
      const temp = [...prev];
      temp[idx] = false;
      return temp;
    })
  }
  const handleTooltipOpenArrowLastDay = (idx) => {
    setTooltipOpenArrowLastDay(prev => {
      const temp = [...prev];
      temp[idx] = true;
      return temp;
    })
  }

  const handleTooltipCloseIconToday = (idx) => {
    setTooltipOpenIconToday(prev => {
      const temp = [...prev];
      temp[idx] = false;
      return temp;
    })
  }
  const handleTooltipOpenIconToday = (idx) => {
    setTooltipOpenIconToday(prev => {
      const temp = [...prev];
      temp[idx] = true;
      return temp;
    })
  }
  const handleTooltipCloseArrowToday = (idx) => {
    setTooltipOpenArrowToday(prev => {
      const temp = [...prev];
      temp[idx] = false;
      return temp;
    })
  }
  const handleTooltipOpenArrowToday = (idx) => {
    setTooltipOpenArrowToday(prev => {
      const temp = [...prev];
      temp[idx] = true;
      return temp;
    })
  }

  /* Cards Data */
  const [todayCardData, setTodayCardData] = useState({});
  const [lastActiveDayData, setLastActiveDayData] = useState({});
  const [studentListDashboard, setStudentListDashboard] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const resTodayCard = await axios.get(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/todaycards/${user_id}`, {
        params: {
          skill_id: userDetails[0]?.skill_id,
          stage_id: userDetails[0]?.stage_id
        }
      }
      );
      const resLastActiveDayCard = await axios.get(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/lastactivedaycards/${user_id}`
      );
      const resStudentListDashboard = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/studentlist/${institute_id}`);
      setStudentListDashboard(resStudentListDashboard.data);
      setTodayCardData(resTodayCard.data);
      setLastActiveDayData(resLastActiveDayCard.data);
    };
    try {
      fetchData();
    } catch (error) {
      console.error(error);
    }
  }, [user_id, userDetails]);

  const renderDaySpecificData = (day, description) => {
    if (day === 'Today') {
      if (todayCardData[0] === undefined) return;
      if (description === 'No. of Attempts') return todayCardData[0]?.No_of_Attempts ?? '00';
      else if (description === 'No. of Questions') return todayCardData[0]?.No_of_Questions ?? '00';
      else if (description === 'Total Time') {
        let tempTime;
        try {
          tempTime = todayCardData[0].Total_time.split(':');
          tempTime = tempTime[0] + 'h' + ' ' + tempTime[1] + 'm' + ' ' + tempTime[2] + 's';
          return tempTime;
        } catch (error) {
          tempTime = '00h 00m 00s';
          console.error(error);
        }
      }
      else if (description === 'Accuracy') return (todayCardData[0]?.Accuracy ?? '0') + '%';
    } else if (day === 'Last Active Day') {
      if (lastActiveDayData[0] === undefined) return;
      if (description === 'No. of Attempts') return lastActiveDayData[0]?.No_of_Attempts ?? '00';
      else if (description === 'No. of Questions') return lastActiveDayData[0]?.No_of_Questions ?? '00';
      else if (description === 'Total Time') {
        let tempTime;
        try {
          tempTime = lastActiveDayData[0]?.Total_time.split(':');
          tempTime = tempTime[0] + 'h' + ' ' + tempTime[1] + 'm' + ' ' + tempTime[2] + 's';
        } catch (error) {
          tempTime = '00h 00m 00s'
        }
        return tempTime;
      }
      else if (description === 'Accuracy') return (lastActiveDayData[0]?.Accuracy ?? '0') + '%';
    }
  }
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  /* Drawer - Filter */
  const [FilterDrawerState, setFilterDrawerState] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setFilterDrawerState(open);
  };
  return (
    <>
      <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} setLogoutDialog={setLogoutDialog} />
      <div className={styles.container}>
        <div className={styles.heading}>
          <div onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <NavBarHamburgerIcon className={styles.svgIcon} />
          </div>
          <div className={styles.headingPart}>
            <Link to={`/timeline/${user_id}`}>
              <button className='filledBtnSml'>Timeline</button>
            </Link>
            <FilterIcon style={{ cursor: 'pointer' }} onClick={toggleDrawer(true)} />
          </div>
        </div>
        <SwipeableDrawer
          PaperProps={{ style: { width: '100%' } }}
          anchor='right'
          open={FilterDrawerState}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <SidenavRightMobile setFilterDrawerState={setFilterDrawerState} studentsList={studentListDashboard} />
        </SwipeableDrawer>
        <div className={styles.userDetails}>
          <div className={styles.userName}>{userDetails[0]?.name}</div>
          <div className={styles.userCurrentPerformance}>
            <div>{userDetails[0]?.skill_name}</div>
            <div>{userDetails[0]?.stage_name}</div>
            <div>{userDetails[0]?.percent_skill_completion}%</div>
          </div>
        </div>
        <div className={styles.activeDay}>
          <div>Last Active Day</div>
          <div>Today</div>
        </div>
      </div>
      <div className={styles.dataContainer}>
        <div className={styles.activeDayCards}>
          <div className={styles.activeDayCardTitle}>
            <NoOfAttemptsIcon />
            <div>
              Number of Attempts
              <ClickAwayListener onClickAway={() => handleTooltipClose1('card1')}>
                <Tooltip
                  arrow={true}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  placement='top'
                  onClose={() => handleTooltipClose1('card1')}
                  open={tooltipOpen1.card1}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  slotProps={{
                    tooltip: {
                      style: { backgroundColor: "transparent !important" },
                      className: styles.tooltipLabel
                    }
                  }}
                  title={
                    <div className={styles.tooltipStyle} style={{ padding: '0.375rem 0.25rem' }}>
                      No. of Attempts per day
                    </div>
                  }
                >
                  <div onClick={() => handleTooltipOpen1('card1')}>
                    <TooltipIcon className={styles.tooltipIconStyle} />
                  </div>
                </Tooltip>
              </ClickAwayListener>
            </div>
          </div>
          <div className={styles.activeDayCard}>
            <div>{lastActiveDayData[0]?.No_of_Attempts}</div>
            <div>{todayCardData[0]?.No_of_Attempts}</div>
          </div>
        </div>
        <div className={styles.activeDayCards}>
          <div className={styles.activeDayCardTitle}>
            <NoOfQuestionsIcon />
            <div>
              Number of Questions
              <ClickAwayListener onClickAway={() => handleTooltipClose1('card2')}>
                <Tooltip
                  arrow={true}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  placement='top'
                  onClose={() => handleTooltipClose1('card2')}
                  open={tooltipOpen1.card2}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  slotProps={{
                    tooltip: {
                      style: { backgroundColor: "transparent !important" },
                      className: styles.tooltipLabel
                    }
                  }}
                  title={
                    <div className={styles.tooltipStyle} style={{ padding: '0.375rem 0.25rem' }}>
                      No. of questions solved
                    </div>
                  }
                >
                  <div onClick={() => handleTooltipOpen1('card2')}>
                    <TooltipIcon className={styles.tooltipIconStyle} />
                  </div>
                </Tooltip>
              </ClickAwayListener>
            </div>
          </div>
          <div className={styles.activeDayCard}>
            <div>{lastActiveDayData[0]?.total_questions}</div>
            <div>{todayCardData[0]?.total_questions}</div>
          </div>
        </div>
        <div className={styles.activeDayCards}>
          <div className={styles.activeDayCardTitle}>
            <TotalTimeIcon />
            <div>
              Total Time
              <ClickAwayListener onClickAway={() => handleTooltipClose1('card3')}>
                <Tooltip
                  arrow={true}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  placement='top'
                  onClose={() => handleTooltipClose1('card3')}
                  open={tooltipOpen1.card3}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  slotProps={{
                    tooltip: {
                      style: { backgroundColor: "transparent !important" },
                      className: styles.tooltipLabel
                    }
                  }}
                  title={
                    <div className={styles.tooltipStyle} style={{ padding: '0.375rem 0.25rem' }}>
                      Total time
                    </div>
                  }
                >
                  <div onClick={() => handleTooltipOpen1('card3')}>
                    <TooltipIcon className={styles.tooltipIconStyle} />
                  </div>
                </Tooltip>
              </ClickAwayListener>
            </div>
          </div>
          <div className={styles.activeDayCard}>
            <div>{renderDaySpecificData('Last Active Day', "Total Time")}</div>
            <div>{renderDaySpecificData('Today', "Total Time")}</div>
          </div>
        </div>
        <div className={styles.activeDayCards}>
          <div className={styles.activeDayCardTitle}>
            <AccuracyIcon />
            <div>
              Accuracy
              <ClickAwayListener onClickAway={() => handleTooltipClose1('card4')}>
                <Tooltip
                  arrow={true}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  placement='top'
                  onClose={() => handleTooltipClose1('card4')}
                  open={tooltipOpen1.card4}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  slotProps={{
                    tooltip: {
                      style: { backgroundColor: "transparent !important" },
                      className: styles.tooltipLabel
                    }
                  }}
                  title={
                    <div className={styles.tooltipStyle} style={{ padding: '0.375rem 0.25rem' }}>
                      % of correct questions solved
                    </div>
                  }
                >
                  <div onClick={() => handleTooltipOpen1('card4')}>
                    <TooltipIcon className={styles.tooltipIconStyle} />
                  </div>
                </Tooltip>
              </ClickAwayListener>
            </div>
          </div>
          <div className={styles.activeDayCard}>
            <div>{renderDaySpecificData('Last Active Day', "Accuracy")}</div>
            <div>{renderDaySpecificData('Today', "Accuracy")}</div>
          </div>
        </div>

        {/* Analytics Table */}
        <div style={{ fontSize: '0.845rem', marginBottom: '0.25rem' }}>Attempt Summary</div>
        <div>
          <div className={styles.tableHeading}>
            <div>Mission</div>
            <div style={{ textAlign: 'center' }}>Last Active Day</div>
            <div>Total</div>
            <div>Valid</div>
          </div>
          <div className={styles.innerBoard}>
            <div className={styles.tableRow}>
              {
                previousAttemptSummary?.map((mission, index) => (
                  <div className={styles.tableRowRow} key={index}>
                    <div style={{ display: 'flex' }}>
                      {mission.mission_name}
                      <ClickAwayListener onClickAway={() => handleTooltipCloseIconLastDay(index)}>
                        <Tooltip
                          arrow={true}
                          PopperProps={{
                            disablePortal: true,
                          }}
                          placement='top'
                          onClose={() => handleTooltipCloseIconLastDay(index)}
                          open={tooltipOpenIconLastDay[index]}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          slotProps={{
                            tooltip: {
                              style: { backgroundColor: "transparent !important" },
                              className: styles.tooltipLabel
                            }
                          }}
                          title={
                            <div className={styles.tooltipStyle}>
                              <div>No. of Ques.: {mission.max_questions}</div>
                              <div>Total Time: {handleTimeFormat(mission.speed)}</div>
                            </div>
                          }
                        >
                          <div onClick={() => handleTooltipOpenIconLastDay(index)}>
                            <TooltipIcon className={styles.tooltipIconStyle} />
                          </div>
                        </Tooltip>
                      </ClickAwayListener>
                    </div>
                    <div>{mission.attempt_count}</div>
                    <div>{mission.total_attempts}</div>
                    <div>{mission.valid_attempts}</div>
                    <div>
                      <ClickAwayListener onClickAway={() => handleTooltipCloseArrowLastDay(index)}>
                        <Tooltip
                          arrow={true}
                          PopperProps={{
                            disablePortal: true,
                          }}
                          placement='top'
                          onClose={() => handleTooltipCloseArrowLastDay(index)}
                          open={tooltipOpenArrowLastDay[index]}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          slotProps={{
                            tooltip: {
                              style: { backgroundColor: "transparent !important" },
                              className: styles.tooltipLabel
                            }
                          }}
                          title={
                            <table className={styles.tableStyle}>
                              <tr>
                                <td className={styles.label}>Last:</td>
                                <td className={styles.label}>Best:</td>
                              </tr>
                              <tr>
                                <td>
                                  Score: {handleLastScore(mission.mission_id)}/{handleTotalLastScore(mission.mission_id)}<br />
                                  Time: {handleTimeElapsedLastActive(mission.mission_id)}
                                </td>
                                <td>
                                  Score: {handleBestScore(mission.mission_id)}/{handleTotalBestScore(mission.mission_id)}<br />
                                  Time: {handleTimeElapsedBest(mission.mission_id)}
                                </td>
                              </tr>
                            </table>
                          }
                        >
                          <div onClick={() => handleTooltipOpenArrowLastDay(index)}>
                            <GreaterThanIcon />
                          </div>
                        </Tooltip>
                      </ClickAwayListener>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        <div style={{ marginTop: '0.5rem' }}>
          <div className={styles.tableHeading}>
            <div>Mission</div>
            <div>Today</div>
            <div>Total</div>
            <div>Valid</div>
          </div>
          <div className={styles.innerBoard}>
            <div className={styles.tableRow}>
              {
                todayAttemptSummary?.map((mission, index) => (
                  <div className={styles.tableRow}>
                    <div>
                      {mission.mission_name}
                      <ClickAwayListener onClickAway={() => handleTooltipCloseIconToday(index)}>
                        <Tooltip
                          arrow={true}
                          PopperProps={{
                            disablePortal: true,
                          }}
                          placement='top'
                          onClose={() => handleTooltipCloseIconToday(index)}
                          open={tooltipOpenIconToday[index]}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          slotProps={{
                            tooltip: {
                              style: { backgroundColor: "transparent !important" },
                              className: styles.tooltipLabel
                            }
                          }}
                          title={
                            <table className={styles.tableStyle}>
                              <tr>
                                <td className={styles.label}>Last:</td>
                                <td className={styles.label}>Best:</td>
                              </tr>
                              <tr>
                                <td>
                                  Score: {mission.last_score}/{mission.max_questions}<br />
                                  Time: {handleTimeFormat(mission.last_time)}
                                </td>
                                <td>
                                  Score: {mission.best_score}/{mission.max_questions}<br />
                                  Time: {handleTimeFormat(mission.best_time)}
                                </td>
                              </tr>
                            </table>
                          }
                        >
                          <div onClick={() => handleTooltipOpenIconToday(index)}>
                            <TooltipIcon className={styles.tooltipIconStyle} />
                          </div>
                        </Tooltip>
                      </ClickAwayListener>
                    </div>
                    <div>{mission.attempt_count}</div>
                    <div>{mission.total_attempts}</div>
                    <div>{mission.valid_attempts}</div>
                    <div>
                      <ClickAwayListener onClickAway={() => handleTooltipCloseArrowToday(index)}>
                        <Tooltip
                          arrow={true}
                          PopperProps={{
                            disablePortal: true,
                          }}
                          placement='top'
                          onClose={() => handleTooltipCloseArrowToday(index)}
                          open={tooltipOpenArrowToday[index]}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          slotProps={{
                            tooltip: {
                              style: { backgroundColor: "transparent !important" },
                              className: styles.tooltipLabel
                            }
                          }}
                          title={
                            <table className={styles.tableStyle}>
                              <tr>
                                <td className={styles.label}>Last:</td>
                                <td className={styles.label}>Best:</td>
                              </tr>
                              <tr>
                                <td>
                                  Score: {mission.last_score}/{mission.max_questions}<br />
                                  Time: {handleTimeFormat(mission.last_time)}
                                </td>
                                <td>
                                  Score: {mission.best_score}/{mission.max_questions}<br />
                                  Time: {handleTimeFormat(mission.best_time)}
                                </td>
                              </tr>
                            </table>
                          }
                        >
                          <div onClick={() => handleTooltipOpenArrowToday(index)}>
                            <GreaterThanIcon />
                          </div>
                        </Tooltip>
                      </ClickAwayListener>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
      <div className={styles.navbarBottom}>
        <Link to={'/dashboard'}>
          <SmartsaHatColorIcon fill='#828282' />
        </Link>
      </div>
    </>
  )
}

export default StudentProfileMobile;