import React, { useEffect, useState } from 'react';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import SmartsaIcon from '../../assets/auth-icons/smartsa-icon-desktop.svg';
import axios from 'axios';
import '../Sidenav/Sidenav.css'
import { ReactComponent as LogoutIcon } from '../../assets/DashboardIcons/LogOutIcon.svg'
import { ReactComponent as ProfileIcon } from '../../assets/DashboardIcons/ProfileIcon.svg'
import { ReactComponent as MyAccountIcon } from '../../assets/DashboardIcons/MyAccountIcon.svg'
import { ReactComponent as AboutUsIcon } from '../../assets/DashboardIcons/AboutUsIcon.svg'
import { ReactComponent as HowItWorksIcon } from '../../assets/DashboardIcons/HowItWorksIcon.svg'
import { ReactComponent as ContactUsIcon } from '../../assets/DashboardIcons/ContactUsIcon.svg'

import isDesktop from '../../utils/isDesktop';

const Sidebar = ({ isOpen, onClose, setLogoutDialog }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  const handleLogout = async () => {
    localStorage.clear();
    navigate('/');
  };

  /* Getting Institute/Teacher Name */
  const [teacherDetail, setTeacherDetail] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const institute_id = localStorage.getItem('institute_id');
        if (institute_id) {
          const resTeacherDetails = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/teacherprofileedit/${institute_id}`);
          setTeacherDetail(resTeacherDetails.data[0]);
          console.log(resTeacherDetails.data[0]);
        }
      } catch (error) {
        console.log(error);
        return error;
      }
    }
    if(token) fetchData();
  }, []);
  const isDesktopDevice = isDesktop();
  return (
    <Drawer anchor="left" open={isOpen} onClose={onClose} PaperProps={{ sx: { width: '11.25rem', backgroundColor: "#EAF1F7", top: '2.67rem', height: 'auto', borderRadius: '0 0.974rem 0.974rem 0', overflowX: 'hidden' } }}>
      <Link to="/dashboard" style={{ textDecoration: 'none' }}>
        <div className='username-text'>
          <div className='sidenav-name'>Hi, {teacherDetail?.institute_name}</div>
          <Typography component="div">
            <img style={{ width: '1rem', height: '1.625rem' }} src={SmartsaIcon} alt="Logo" />
          </Typography>
        </div>
      </Link>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
        <div>
          <List sx={{ paddingBottom: '0', paddingTop: '0' }}>
            <ListItemButton onClick={() => navigate('/teacherprofile')} style={{ padding: '0' }}>
              <ListItem className='list-item' style={{gap: '0.4rem'}}>
                <ListItemIcon sx={{ minWidth: 'auto', width: '2.125rem', height: '2.125rem' }}>
                  {teacherDetail?.profile_photo_url ? <img src={teacherDetail?.profile_photo_url} style={{ margin: '0.1rem auto auto 0.25rem', width: '90%', height: '90%', borderRadius: '16px', objectFit: 'cover'}}/> : <ProfileIcon />}
                </ListItemIcon>
                <ListItemText disableTypography className='list-item-text'>Profile</ListItemText>
              </ListItem>
            </ListItemButton>
          </List>
          <List sx={{ paddingBottom: '0', paddingTop: '0' }}>
            <ListItemButton onClick={() => navigate('/accountdetails')} style={{ padding: '0' }}>
              <ListItem className='list-item'>
                <ListItemIcon sx={{ minWidth: 'auto' }}>
                  <MyAccountIcon />
                </ListItemIcon>
                <ListItemText disableTypography className='list-item-text'>My Account</ListItemText>
              </ListItem>
            </ListItemButton>
          </List>
          <List sx={{ paddingBottom: '0', paddingTop: '0' }}>
            <ListItemButton onClick={() => navigate('/about-us')} style={{ padding: '0' }}>
              <ListItem className='list-item'>
                <ListItemIcon sx={{ minWidth: 'auto' }}>
                  <AboutUsIcon />
                </ListItemIcon>
                <ListItemText disableTypography className='list-item-text'>About Us</ListItemText>
              </ListItem>
            </ListItemButton>
          </List>
          <List sx={{ paddingBottom: '0', paddingTop: '0' }}>
            <ListItemButton onClick={() => navigate('/how-it-works')} style={{ padding: '0' }}>
              <ListItem className='list-item'>
                <ListItemIcon sx={{ minWidth: 'auto' }}>
                  <HowItWorksIcon />
                </ListItemIcon>
                <ListItemText disableTypography className='list-item-text'>How It Works</ListItemText>
              </ListItem>
            </ListItemButton>
          </List>
          <List sx={{ paddingBottom: '0', paddingTop: '0' }}>
            <ListItemButton onClick={() => navigate('/help')} style={{ padding: '0' }}>
              <ListItem className='list-item'>
                <ListItemIcon sx={{ minWidth: 'auto' }}>
                  <ContactUsIcon />
                </ListItemIcon>
                <ListItemText disableTypography className='list-item-text'>Contact Us</ListItemText>
              </ListItem>
            </ListItemButton>
          </List>
        </div>
        <div>
          <List>
            <ListItemButton onClick={handleLogout} sx={{ padding: '0', paddingTop:'2rem' }}>
              <ListItem className='list-item' style={{ paddingLeft: '0', border: 'none' }}>
                <ListItemIcon sx={{ minWidth: 'auto' }}>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText disableTypography className='list-item-text'>
                  Logout
                </ListItemText>
              </ListItem>
            </ListItemButton>
          </List>
        </div>
      </div>
    </Drawer>
  );
};

export default Sidebar;
