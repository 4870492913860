import React, { useEffect, useState } from 'react'

/* ChartJS & react-chart-2 */
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import Wrapper from '../WrapperDesktop/Wrapper';
import { ReactComponent as TooltipIcon } from '../../assets/DashboardIcons/TooltipIcon.svg';
import isDesktop from '../../utils/isDesktop';
import TimelineMobile from './TimelineMobile';

/* Styles */
import styles from './Timeline.module.css';
import {ReactComponent as HighestStreakIcon} from '../../assets/TimelineIcons/HighestStreakIcon.svg';
import {ReactComponent as LongestSprintIcon} from '../../assets/TimelineIcons/LongestSprintIcon.svg';
import {ReactComponent as AccuracyIcon} from '../../assets/DashboardIcons/AccuracyIcon.svg';
import {ReactComponent as NoOfQuestionsIcon} from '../../assets/DashboardIcons/NoOfQuestionsIcon.svg';
import {ReactComponent as TotalTimeIcon} from '../../assets/DashboardIcons/TotalTimeIcon.svg';
import {ReactComponent as TotalTimePerQuesIcon} from '../../assets/TimelineIcons/TotalTimePerQuesIcon.svg';
import {ReactComponent as AvgTimePerDayIcon} from '../../assets/TimelineIcons/AvgTimePerDayIcon.svg';
import {ReactComponent as TotalQuesPerDayIcon} from '../../assets/TimelineIcons/TotalTimePerQuesIcon.svg';
import {ReactComponent as CorrectAnswersIcon} from '../../assets/TimelineIcons/CorrectAnswersIcon.svg';
import SidenavRight from './TimelineSidenavRight';
import axios from 'axios';
import { useParams } from 'react-router-dom';

/* ChartJS */
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);
const Timeline = () => {
  /* Receiving Data from DB */
  const isDesktopDevice = isDesktop();
  const [timeline, setTimeline] = useState({});
  const [timelineCustomDate, setTimelineCustomDate] = useState([]);
  const [trackNavbarRight, setTrackNavbarRight] = useState([]);
  const { user_id } = useParams();
  useEffect(() => {
    console.log(trackNavbarRight, " hello track NavbarRight se aaya date");
    const fetchUserTimeline = async () => {
      try {
        console.log("not caught");
        const resUserTimeline = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/timeline/${user_id}`);
        setTimeline(resUserTimeline.data);
      } catch (error) {
        console.log("Error in fetchUserTimeline",error);
      }
     
    }
    const fetchUserTimelineFilter = async () => {
      // console.log();
      const resUserTimelineFilter = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/timelinefilter/${user_id}`, {
        params: {
          fromDate: trackNavbarRight.date.fromDate,
          toDate: trackNavbarRight.date.toDate
        }
      });
      console.log(resUserTimelineFilter.data, " data received from db for custom date");
      setTimeline(resUserTimelineFilter.data);
    }
    // console.log(trackNavbarRight.length, " trackNavbarRight");
    if(isDesktopDevice) {
      if(trackNavbarRight.length === 0) {
        fetchUserTimeline();
      }
      else fetchUserTimelineFilter();
    }
  }, [trackNavbarRight]);

  const getFormatedTime = (time) => {
    // 00h 00m 00s
    // console.log(time?.split(':'));
    // return null;
    if(!time) return '0h 0m 0s';
    const t = time?.split(':');
    return t && (t[0] + 'h ' + t[1] + 'm ' + t[2] + 's');
  }

  const months = [
    "January", "February", "March", "April", "May", "June", 
    "July", "August", "September", "October", "November", "December"
  ];
  let dateArray = [], months_date = [], missions = [], sprints = [];
  
  if(trackNavbarRight.event === 'apply'){
    const fd = trackNavbarRight.date.fromDate;
    const td = trackNavbarRight.date.toDate;
    missions = []; sprints = [];
    for(let d = new Date(fd); d <= new Date(td); d.setDate(d.getDate() + 1)){
      timeline.sprint_mission_barGraph?.map((obj, idx) => {
        const tempDate = parseInt(obj.date_time.slice(8, 10));
        const monthIdx = parseInt(obj.date_time.slice(5, 7));
        if(tempDate === d.getDate() && monthIdx === d.getMonth() + 1){
          missions.push(obj.missions);
          sprints.push(obj.sprints);
          return;
        }
        if(idx + 1 === timeline.sprint_mission_barGraph.length){
          missions.push(0);
          sprints.push(0);
        }
      });
      const tempDate = d.getDate();
      console.log(tempDate);
      // const monthIdx = d.getMonth();
      dateArray.push(tempDate);
    }
    console.log(dateArray, " date of filters");
  } else{
    timeline.sprint_mission_barGraph?.map(obj => {
      const tempDate = parseInt(obj.date_time.slice(8, 10));
      const monthIdx = parseInt(obj.date_time.slice(5, 7));
      dateArray.push(tempDate);
      console.log(`${tempDate} ${months[monthIdx - 1]} check date`);
      months_date.push(`${tempDate} ${months[monthIdx - 1]}`);
      missions.push(obj.missions);
      sprints.push(obj.sprints);
    });
  }
  console.log(dateArray, missions, sprints, " got the data ready raj");
  // console.log(year_month_barGraph, sprintCount_barGraph, missionCount_barGraph, " hello bragraph");
  // ['2024-04', '2024-05'] | [24, 12] | [24, 14] ' hello bragraph'

  /* CustomDate Filters */
  // console.log(trackNavbarRight, " hello track")
  // if(trackNavbarRight.event === 'apply'){
  //   year_month_barGraph = [];
  //   missionCount_barGraph = [];
  //   sprintCount_barGraph = [];
  //   timelineCustomDate.map(obj => {
  //     year_month_barGraph.push(obj.createdAt.substring(0, 10));
  //     missionCount_barGraph.push(obj.mission_count);
  //     sprintCount_barGraph.push(obj.sprint);
  //   });
  // }
  
  /* ChartJS */
  const data = {
    labels: dateArray,
    datasets: [
      {
        label: 'Sprints',
        data: sprints,
        backgroundColor: '#44ccff',
        borderWidth: 1
      },
      {
        label: 'Missions',
        data: missions,
        backgroundColor: 'white',
        borderWidth: 1
      }
    ]
  }
  const options = {
    aspectRatio: 1,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          title: function(tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            return months_date[index]; // Custom tooltip title based on your array
          }
        },
        backgroundColor: '#000000', // Black background
        titleColor: '#FFFFFF', // White text color for title
        bodyColor: '#FFFFFF', // White text color for body
        displayColors: true // Show color box in the tooltip
      },
      legend: {
        labels: {
          color: 'white'
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
          color: 'white'
        },
        ticks: {
          color: 'white',
          maxRotation: "0",
        },
        grid: {
          color: 'transparent',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Count',
          color: 'white'
        },
        ticks: {
          color: 'white'
        },
      },
    },
    layout: {
      padding: {
        left: 16,
        right: 16,
        top: 10,
        bottom: 10,
      },
    },
  }
  return (
    <>
    {
      isDesktopDevice ? 
    <>
      <Wrapper />
      {/* UserName & Level */}
      <div className={styles.usernameLevel} style={{ display: 'flex', justifyContent: 'space-between', margin: '2.564vw 18.6vw 3.333vw 8.462vw', alignItems: 'center' }} >
        <div className={styles.userName} style={{fontFamily: 'Roboto', fontWeight: '500', fontSize: '1.538vw'}}>
          Timeline - {timeline.name}
        </div>
        <div className={styles.userLevel} style={{
            border: '2px solid #313C63',
            display: 'flex',
            justifyContent: 'space-around',
            borderRadius: '8px',
            width: '8.974vw',
            height: '2.564vw',
            alignItems: 'center',
            textAlign: 'center',
            fontSize: '1.026vw',
            fontWeight: '400',
            marginRight: '2vw',
            boxShadow: '1.3px 1.3px 2.6px 0px #AEAEC066, -0.87px -0.87px 2.6px 0px #FFFFFF',
            cursor: 'pointer'
          }}>
          Level {timeline.level}
        </div>
      </div>

      {/* Chart & Table */}

      <div className={styles.chartTableContainer}>
        <div className={styles.chart}>
          <Bar
          data={data}
          options={options}
          >
          </Bar>
        </div>
        <div className={styles.table}>
          <div className={styles.row}>
            <div>
              <HighestStreakIcon style={{width: '1.026vw'}} />
              <div>Highest Streak</div>
            </div>
            <div>{timeline.highest_streak}</div>
          </div>
          <div className={styles.row}>
            <div>
              <LongestSprintIcon style={{width: '1.026vw'}} />
              <div>Longest Sprint</div>
            </div>
            <div>{timeline.longest_sprint}</div>
          </div>
          <div className={styles.row}>
            <div>
              <AccuracyIcon style={{width: '1.026vw'}} />
              <div>Accuracy</div>
            </div>
            <div>{timeline.accuracy}%</div>
          </div>
          <div className={styles.row}>
            <div>
              <NoOfQuestionsIcon style={{width: '1.026vw'}} />
              <div>No. of Questions</div>
            </div>
            <div>{timeline.total_questions}</div>
          </div>
          <div className={styles.row}>
            <div>
              <TotalTimeIcon style={{width: '1.026vw'}} />
              <div>Total Time</div>
            </div>
            <div>{getFormatedTime(timeline.total_time)}</div>
          </div>
          <div className={styles.row}>
            <div>
              <TotalTimePerQuesIcon style={{width: '1.026vw'}} />
              <div>Total Time/Ques.</div>
            </div>
            <div>{getFormatedTime(timeline.totalTime_per_question)}</div>
          </div>
          <div className={styles.row}>
            <div>
              <AvgTimePerDayIcon style={{width: '1.026vw'}} />
              <div>Avg. Time/Day</div>
            </div>
            <div>{getFormatedTime(timeline.avg_time_per_day)}</div>
          </div>
          <div className={styles.row}>
            <div>
              <TotalQuesPerDayIcon style={{width: '1.026vw'}} />
              <div>Total Ques./Day</div>
            </div>
            <div>{timeline.totalQuestions_per_day}</div>
          </div>
          <div className={styles.row}>
            <div>
              <CorrectAnswersIcon style={{width: '1.026vw'}} />
              <div>Correct Answers</div>
            </div>
            <div>{timeline.correct_answers}</div>
          </div>
        </div>
      </div>

      <SidenavRight setTimelineCustomDate = {setTimelineCustomDate} setTrackNavbarRight = {setTrackNavbarRight} />
      
    </> : 
    <TimelineMobile />
    }
    </>
  )
}

export default Timeline