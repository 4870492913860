import React, { useEffect, useState } from 'react'
import Wrapper from '../WrapperDesktop/Wrapper'
import SidenavRight from '../Common/FilterSidenav/SidenavRight'
import styles from './DashboardMobile.module.css'
import { ReactComponent as GreaterThanIcon } from '../../assets/DashboardIcons/GreaterThanIcon.svg'
import { Link, useLocation } from 'react-router-dom'
import axios from 'axios'
import { TablePagination, TextField, IconButton, SwipeableDrawer } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import Sidebar from '../Sidenav/Sidenav';
import { ReactComponent as FilterIcon } from '../../assets/DashboardIcons/FilterIcon.svg';
import { ReactComponent as NavBarHamburgerIcon } from '../../assets/DashboardIcons/NavBarHamburgerIcon.svg';
import { ReactComponent as SmartsaHatColorIcon } from '../../assets/DashboardIcons/SmartsaHatColorIcon.svg';
import SidenavRightMobile from '../Common/FilterSidenav/SidenavRightMobile'

export const IndicatorComponent = ({color}) => {
  return (
    <div className={styles.indicatorDiv}><div className={styles.indicator} style={{ backgroundColor: color }}></div></div>
  );
}

const DashboardMobile = () => {
  const [studentsList, setStudentsList] = useState([]);
  const [totalStudentList, setTotalStudentList] = useState([]);
  const institute_id = localStorage.getItem('institute_id');
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation();
  const fetchStudentData=async()=>{
    try {
      const resStudentsList = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/studentlist/${institute_id}`);
      setStudentsList(resStudentsList.data);
      setTotalStudentList(resStudentsList.data);
      console.log(resStudentsList);
    }
    catch (error) {
      console.log(error);
    }
  }
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  useEffect(() => {
    fetchStudentData()
  }, []);

  /* Pagination | MUI */
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  }
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  }
  const handleTimeFormat = (time) => {
    const timeSplit = time?.split(':');
    console.log(timeSplit);
    return timeSplit ? `${parseInt(timeSplit[0])}h ${parseInt(timeSplit[1])}m ${parseInt(timeSplit[2])}s` : '0h 0m 0s';
  }
  /* Drawer - Filter */
  /* Input Filter Option */
  const [filteredStudents, setFilteredStudents] = useState([]);
  useEffect(() => {
    const filtered = studentsList.filter(stud => {
      return stud.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      stud.status.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setFilteredStudents(filtered);
  }, [searchTerm])
  const [FilterDrawerState, setFilterDrawerState] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setFilterDrawerState(open);
  };
  return (
    <>
      <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} setLogoutDialog={setLogoutDialog} />
      <div className={styles.container}>
        <div className={styles.heading}>
          <div onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <NavBarHamburgerIcon className={styles.svgIcon} />
          </div>
          <div className={styles.headingPart}>
            <TextField
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                    boxShadow: 'none',
                  },
                },
              }}
              InputProps={{
                style: {
                  height: '1.75rem',
                  width: '9.75rem',
                  boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A',
                  padding: '0',
                },
                endAdornment: (
                  // <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                  // </InputAdornment>
                ),
              }}
            />
            <FilterIcon style={{ cursor: 'pointer' }} onClick={toggleDrawer(true)} />
          </div>
        </div>
        <div style={{display: 'flex', flexWrap: 'wrap',gap: '1vw', alignItems: 'center', justifyContent: 'space-between'}}>
          <div style={{fontSize: '1.125', fontWeight: '600'}}>Dashboard</div>
          <TablePagination
            component="div"
            rowsPerPageOptions={[12, 24, 36]}
            count={studentsList ? studentsList.length : 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{scrollbarWidth: 'none'}}
            sx={{
              '.MuiTablePagination-toolbar': {
                paddingRight: '0px',
                paddingLeft: '0px',
                width: '100%',
                justifyContent: 'space-evenly',
              },
              '& .MuiTablePagination-input': {
                marginRight: '5px',
              },
              '& .MuiTablePagination-actions': {
                marginLeft: '5px !important',
              },
              '& .MuiTablePagination-spacer': {
                display: 'none',
              },
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          />
        </div>
      </div>
      <SwipeableDrawer
          PaperProps={{ style: { width: '100%' } }}
          anchor='right'
          open={FilterDrawerState}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
      >
        <SidenavRightMobile setFilterDrawerState={setFilterDrawerState} studentsList={totalStudentList} />
      </SwipeableDrawer>
      
      <div className={styles.legendsParameter}>
        <div className={styles.legend}>
          <IndicatorComponent color={'#E53D44'} />
          <div>Critical</div>
        </div>
        <div className={styles.legend}>
          <IndicatorComponent color={'#133593'} />
          <div>Watchful</div>
        </div>
        <div className={styles.legend}>
          <IndicatorComponent color={'#3C9313'} />
          <div>Outstanding</div>
        </div>
      </div>
      
      <div style={{margin: '0.75rem', marginBottom: '5rem'}}>
        <div className={styles.tableHeading}>
          <div>Name</div>
          <div>Class</div>
          <div>Overall Avg. Time/Day</div>
          <div>Last Week Avg. Time/Day</div>
          <div>Last Active</div>
          <div>Status</div>
        </div>
        <div className={styles.innerBoard} style={{ height: rowsPerPage <= 12 ? 'auto' : '42rem', overflowY: rowsPerPage > 12 && 'auto' }}>
          <div className={styles.tableRow}>
            {
              (searchTerm.length > 0 ? filteredStudents : studentsList ) ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((student, index) => (
                <Link to={`/studentprofile/${student.user_id}`} style={{ textDecoration: 'none', color: 'black' }} key={student.user_id}>
                  <div className={styles.tableRowRow}>
                    <div>{student.name}</div>
                    <div>{student.user_class}</div>
                    <div>{handleTimeFormat(student.avg_time_per_day)}</div>
                    <div>{handleTimeFormat(student.last_week_avg_time_per_day)}</div>
                    <div>{student.last_active_date === null ? 0 : student.last_active_date} days</div>
                    <div style={{
                      background: student.status === 'Critical' ? '#E53D44' : student.status === 'OutStanding' ? '#3C9313' : '#133593',
                      width: '4px',
                      height: '4px',
                      borderRadius: '2px',
                      border: 'none',
                      margin: 'auto'
                    }}>
                    </div>
                  </div>
                </Link>
              ))
            }
          </div>
        </div>
      </div>
      <div className={styles.navbarBottom}>
          <SmartsaHatColorIcon 
            className={`${location.pathname == '/dashboard' && styles.navbarBottomIconActive}`}
            fill={location.pathname == '/dashboard'? '#3FCBFF':'#828282'} 
          />
      </div>
    </>
  )
}

export default DashboardMobile;