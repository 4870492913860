import React, { useEffect, useState } from 'react';
import styles from './TimelineSidenavRightMobile.module.css';

/* SearchIcon, FilterIcon, DropDownIcon */
import { ReactComponent as FilterIcon } from '../../assets/DashboardIcons/FilterIcon.svg';
import { ReactComponent as DropDownIcon } from '../../assets/DashboardIcons/DropDownIcon.svg';
import { ReactComponent as DropUpIcon } from '../../assets/DashboardIcons/DropUpIcon.svg';
import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
import { Box, Tabs, Tab, Link } from '@mui/material';
import PropTypes from 'prop-types';

function samePageLinkNavigation(event) {
  if (
    event.defaultPrevented ||
    event.button !== 0 ||
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false;
  }
  return true;
}
function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        if (samePageLinkNavigation(event)) {
          event.preventDefault();
        }
      }}
      aria-current={props.selected && 'page'}
      {...props}
    />
  );
}
LinkTab.propTypes = {
  selected: PropTypes.bool,
};
const SidenavRight = ({ setTimelineCustomDate = () => { }, setTrackNavbarRight = () => { }, setTrackNavbarFilter = () => { } }) => {
  const [weekTab, setWeekTab] = useState(false);
  const [monthTab, setMonthTab] = useState(false);
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const [isSubDropDownClick1, setIsSubDropDownClick1] = useState(true);
  const [isSubDropDownClick2, setIsSubDropDownClick2] = useState(false);
  const [isSubDropDownClick3, setIsSubDropDownClick3] = useState(false);
  const [current, setCurrent] = useState({ isActive: true, index: -1 });
  const [searchParam, setSearchParm] = useSearchParams();
  const [selectedTabMUI, setSelectedTabMUI] = useState(
    Number(searchParam.get('tab')) || 0
  );
  const handleChangeTabsMUI = (e, newTabValue) => {
    setSelectedTabMUI((prevTabValue) => {
      setSearchParm({ Tab: newTabValue });
      return newTabValue;
    });
    // setStatisticsProfileData(null)
  };
  useEffect(() => {
    if(selectedTabMUI === 0) {
      setMonthTab(false);
    }else{
      setWeekTab(false);
    }
  }, [selectedTabMUI])

  /* Filter Variables */
  const [customDate, setCustomDate] = useState([]);
  const [last4Months, setLast4Months] = useState([]);
  const [last4Weeks, setLast4Weeks] = useState([]);

  const handleFilterCustomDate = (e) => {
    const { name, value } = e.target;
    setCustomDate((prev) => ({
      ...prev,
      [name]: value
    }));
  }

  const handleWeekTabChange = (e, newValue) => {
    setWeekTab(newValue);
  }
  useEffect(() => {
    if(last4Weeks.length > 0 && weekTab !== false) handleFilterCustomWeekAndMonth(weekTab, 'weekrange', last4Weeks[weekTab]);
  }, [weekTab]);
  const handleMonthTabChange = (e, newValue) => {
    setMonthTab(newValue);
  }
  useEffect(() => {
    if(last4Months.length > 0 && monthTab !== false) handleFilterCustomWeekAndMonth(monthTab, 'month', last4Months[monthTab]);
  }, [monthTab]);

  const handleFilterCustomWeekAndMonth = (idx, name, value) => {
    if (name === 'month') {
      setCurrent(prev => ({ ...prev, index: idx + 4 }));
      console.log(value, " got month");
      const date = new Date();
      console.log(date.getFullYear(), " full year");
      const yr = date.getFullYear();
      const mth = String(months.indexOf(value) + 1).padStart(2, '0');
      // 2024-08-01 2024-08-12  month date got

      /* to calculate last date of any month */
      const lastday = (y, m) => {
        return new Date(y, m + 1, 0).getDate();
      }
      let dy = '', currMonthIdx = date.getMonth(), givenMonthIdx = months.indexOf(value);
      if (givenMonthIdx === currMonthIdx)
        dy = String(date.getDate()).padStart(2, '0');
      else dy = String(lastday(yr, givenMonthIdx));
      console.log(yr, mth, dy, givenMonthIdx, currMonthIdx, " got the details date");
      const fromDate = `${yr}-${mth}-01`;
      const toDate = `${yr}-${mth}-${dy}`;
      console.log(fromDate, toDate, ' month date got');
      // setCustomDate({fromDate: fromDate, toDate: toDate});
      setTrackNavbarFilter({ event: 'apply', date: { fromDate: fromDate, toDate: toDate } });
      setTrackNavbarRight({ event: 'apply', date: { fromDate: fromDate, toDate: toDate } })
    } else if (name === 'weekrange') {
      setCurrent(prev => ({ ...prev, index: idx }));
      // weekrange 2024-07-11 - 2024-07-17
      const temp = value?.split(' - ');
      const fromDate = temp[0];
      const toDate = temp[1];
      console.log(fromDate, toDate, " weekrange date");
      // setCustomDate({fromDate: fromDate, toDate: toDate});
      setTrackNavbarFilter({ event: 'apply', date: { fromDate: fromDate, toDate: toDate } });
      setTrackNavbarRight({ event: 'apply', date: { fromDate: fromDate, toDate: toDate } })
    }
  }

  /* Last Four Weeks Calculations */
  const HandleLastFourWeeks = () => {
    const formatWeek = (start, end) => {
      const startString = `${start.getFullYear()}-${String(start.getMonth() + 1).padStart(2, '0')}-${String(start.getDate()).padStart(2, '0')}`;
      const endString = `${end.getFullYear()}-${String(end.getMonth() + 1).padStart(2, '0')}-${String(end.getDate()).padStart(2, '0')}`;
      return `${startString} - ${endString}`;
    };
    const today = new Date();
    const weeks = [];
    for (let i = 0; i < 4; i++) {
      const endOfWeek = new Date(today);
      endOfWeek.setDate(today.getDate() - (7 * i));
      const startOfWeek = new Date(endOfWeek);
      startOfWeek.setDate(endOfWeek.getDate() - 6);
      weeks.unshift(formatWeek(startOfWeek, endOfWeek));
    }
    weeks.reverse();
    return weeks;
  };
  /* Last Four Months Calculations */
  const handleLastFourMonths = () => {
    const currentMonthIndex = new Date().getMonth();
    const lastFourMonths = [];
    for (let i = 0; i < 4; ++i) {
      const monthIndex = (currentMonthIndex - i + 12) % 12;
      lastFourMonths.unshift(months[monthIndex]);
    }
    lastFourMonths.reverse();
    return lastFourMonths;
  }
  useEffect(() => {
    setLast4Months(handleLastFourMonths());
    setLast4Weeks(HandleLastFourWeeks());
    setWeekTab(0);
  }, []);

  return (
    <div className={styles.sidenav}>
      <Tabs
        value={selectedTabMUI}
        onChange={handleChangeTabsMUI}
        aria-label="nav tabs example"
        centered={true}
        role="navigation"
        className={styles.muiTabsStyle}
        textColor='inherit'
        TabIndicatorProps={{ style: { background: '#00034A' } }}
      >
        <Tab sx={{ textTransform: "none", flex: '1' }} label={
          <div className={styles.subDropdown} >
            <p className={styles.timelineSideNavRightP}>Weeks</p>
          </div>
        }
        />
        <Tab sx={{ textTransform: "none", flex: '1' }} label={
          <div className={styles.subDropdown} >
            <p className={styles.timelineSideNavRightP}>Month</p>
          </div>
        }
        />
        {/* <LinkTab label={
          <div className={styles.subDropdown} onClick={handleClickSubDropdown3}>
            <p className={styles.timelineSideNavRightP}>Custom</p>
          </div>
        }
        /> */}
      </Tabs>
      {
        selectedTabMUI == 0 && (
          <Tabs
            value={weekTab} 
            onChange={handleWeekTabChange}
            variant='scrollable'
            allowScrollButtonsMobile
            textColor='inherit'
            TabIndicatorProps={{ style: { display: 'none' } }}
            sx={{
              marginTop: '1.125rem',
              "& .MuiTabs-flexContainer": {
                gap: "0.625rem",
              },
              "& .MuiTab-root": {
                borderRadius: "4px",
                backgroundColor: "none",
                color: "black",
                border: '1px solid #b7b7b7'
              },
              "& .Mui-selected": {
                color: "white !important",
                backgroundColor: "#313C63",
              },
              "& .MuiTabs-indicator": {
                color: "white",
                backgroundColor: "none",
              },
            }}
          >
            {
              last4Weeks?.map((weekRange, index) => (
                <Tab 
                sx={{
                  textTransform: "none",
                  "& .MuiButtonBase-root": {
                    padding: '0.5rem 1rem',
                  }
                }}
                  key={index} label={
                    <div key={index}>
                      {index === 0 ?
                        ('This week')
                        :
                        (`${weekRange.substring(8, 10)} ${months[Number(weekRange.substring(5, 7)) - 1]} - 
                      ${weekRange.substring(21)} ${months[Number(weekRange.substring(18, 20)) - 1]}`)
                      }
                    </div>
                  } />
              ))
            }
          </Tabs>
        )
      }
      {
        selectedTabMUI == 1 && (
          <Tabs
            value={monthTab} 
            onChange={handleMonthTabChange}
            variant='scrollable'
            allowScrollButtonsMobile
            textColor='inherit'
            TabIndicatorProps={{
              style: { display: 'none' }
            }}
            sx={{
              marginTop: '1.125rem',
              "& .MuiTabs-flexContainer": {
                gap: "0.625rem",
              },
              "& .MuiTab-root": {
                borderRadius: "4px",
                backgroundColor: "none",
                color: "black",
                border: '1px solid #b7b7b7',
              },
              "& .Mui-selected": {
                color: "white !important",
                backgroundColor: "#313C63",
              },
              "& .MuiTabs-indicator": {
                color: "white",
                backgroundColor: "none",
              },
            }}
          >
            {
              last4Months.map((month, index) => (
                <LinkTab sx={{ textTransform: "none" }} key={index} label={
                  <div
                    key={index}>{month}
                  </div>
                } />
              ))
            }
          </Tabs>
        )
      }
      {/* {
          !isSubDropDownClick3 && (
            <>
              <div className={styles.btnsPlacing}>
                <button className={styles.btn} style={{ backgroundColor: '#313C63', color: 'white', marginRight: '8px', fontSize: '0.75rem' }} onClick={() => {
                  setTrackNavbarFilter([]);
                  setTrackNavbarRight([]);
                  setCurrent(prev => ({ ...prev, index: -1 }));
                }}>Clear</button>
              </div>
            </>
          )
        } */}
    </div>
  )
}

export default SidenavRight