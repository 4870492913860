import React, { useEffect, useState } from 'react';
import Wrapper from '../WrapperDesktop/Wrapper';
import styles from './AccountDetails.module.css';
import { ReactComponent as CancelledChequeIcon } from '../../assets/accountDetails/CancelledChequeIcon.svg';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import isDesktop from '../../utils/isDesktop';
import AccountDetailsMobile from './AccountDetailsMobile';

const AccountDetails = () => {
  const isDesktopDevice = isDesktop();
  const [teacherDetail, setTeacherDetail] = useState({});
  const [isDataFilled, setIsDataFilled] = useState(true);
  const [isEditBtnClicked, setIsEditBtnClicked] = useState(false);
  const [formData, setFormData] = useState({
    bank_name: '',
    micr_code: '',
    branch_name: '',
    ifsc_code: '',
    account_name: '',
    address: '',
    acct_num: '',
    pan_num: ''
  });

  /* Get formData if it's already present */
  const institute_id = localStorage.getItem('institute_id');
  // console.log(institute_id, " institute ID"); // 10
  // const institute_id = 29;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const resFormData = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/accountdetails/${institute_id}`);
        setFormData(resFormData.data[0]);
        console.log(resFormData.data, " Got form DATA");
      } catch (error) {
        setFormData({});
        setIsDataFilled(false);
        console.log({ Error: 'No such data exist' });
      }
    }
    isDesktopDevice && fetchData();
  }, []);
  // console.log(formData, "Hello");
  const handleChangeAccountDetails = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    // const {bankName, micrCode, branchName, ifscCode, accountName, address, accountNum, panNum} = e.target;
    // console.log(bankName, micrCode, branchName, ifscCode, accountName, address, accountNum, panNum);
  }
  const handleSubmit = async (e=null) => {
    if (e!==undefined && e!==null) {

      e.preventDefault();
    }

    /* check if all fields are filled */
    // const { bank_name, micr_code, ifsc_code, account_name, address, acct_num, pan_num } = formData;
    // console.log(bank_name, micr_code, ifsc_code, account_name, address, acct_num, pan_num);
    // to get all the formData we input
    console.log(formData);
    try {
      console.log(formData, " frontend getting data");
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_KEY}/accountdetails/${institute_id}`, formData);
      if(res.status == 200){
        toast.success('Data Saved Successfully');
      }
      // setFormData({
      //   bank_name: '',
      //   micr_code: '',
      //   branch_name: '',
      //   ifsc_code: '',
      //   account_name: '',
      //   address: '',
      //   acct_num: '',
      //   pan_num: ''
      // });
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong!');
    }
  }
  // const handleClickEditOrSubmit = () => {
  //   if(!formData.bank_name || !formData.micr_code || !formData.branch_name || !formData.ifsc_code || !formData.account_name || !formData.address || !formData.acct_num || !formData.pan_num){
  //       alert('Please fill all Fields');
  //       return;
  //     }
  //   else{
  //     handleSubmit();
  //   }
  // }
  const handleOnSaveBtnClick = () =>{
    handleSubmit();
    setIsEditBtnClicked(prev => !prev);
  }
  return (
    <>
      {isDesktopDevice ? (
        <>
          <Wrapper setTeacherDetail={setTeacherDetail} />
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '40px 300px 40px 132px',
            alignItems: 'center',
            fontSize: '24px',
            fontWeight: '500'
          }}>
            Account Details
          </div>
          <ToastContainer />
          <div className={styles.container}>
            <form className={styles.detailsGrid} onSubmit={handleSubmit}>
          <input type="text" placeholder='Bank Name' name='bank_name' value={formData.bank_name}  onChange={(isEditBtnClicked || !isDataFilled) ? handleChangeAccountDetails : undefined} />
              <input type="text" placeholder='MICR Code' name='micr_code' value={formData.micr_code} onChange={(isEditBtnClicked || !isDataFilled) ? handleChangeAccountDetails : undefined} />
              <input type="text" placeholder='Branch Name' name='branch_name' value={formData.branch_name} onChange={(isEditBtnClicked || !isDataFilled) ? handleChangeAccountDetails : undefined} />
              <input type="text" placeholder='IFSC Code' name='ifsc_code' value={formData.ifsc_code} onChange={(isEditBtnClicked || !isDataFilled) ? handleChangeAccountDetails : undefined} />
              <input type="text" placeholder='Account Name' name='account_name' value={formData.account_name} onChange={(isEditBtnClicked || !isDataFilled) ? handleChangeAccountDetails : undefined} />
              <input type="text" placeholder='Address' name='address' value={formData.address} onChange={(isEditBtnClicked || !isDataFilled) ? handleChangeAccountDetails : undefined} />
              <input type="text" placeholder='Account Number' name='acct_num' value={formData.acct_num} onChange={(isEditBtnClicked || !isDataFilled) ? handleChangeAccountDetails : undefined} />
              <input type="text" placeholder='PAN Number' name='pan_num' value={formData.pan_num} onChange={(isEditBtnClicked || !isDataFilled) ? handleChangeAccountDetails : undefined} />
              <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '4px', cursor: 'pointer', fontSize: '10px', alignItems: 'center' }}>
                <CancelledChequeIcon />
                <div>Upload Cancelled Cheque (To change account number)</div>
              </div>
              {!isDataFilled && <div className={styles.buttonContainer}>
                <button type='submit' className={styles.buttonContainerBtn}
                >Submit</button>
              </div>}
            </form>
            {
              (isDataFilled && !isEditBtnClicked) &&
              (<div className={styles.buttonContainer}>
                <button type='submit' className={styles.buttonContainerBtn}
                  onClick={() => (setIsEditBtnClicked(prev => !prev))}
                >Edit</button>
              </div>)
            }
            {
              isEditBtnClicked &&
              (
                <div className={styles.buttonContainer}>
                  <button type='submit' className={styles.buttonContainerBtn1}
                    onClick={() => (setIsEditBtnClicked(prev => !prev))}
                  >Cancel</button>
                  <button type='submit' className={styles.buttonContainerBtn}
                    onClick={handleOnSaveBtnClick}
                  >Save</button>
                </div>
              )
            }
          </div>
        </>
      ) :
        (
          <AccountDetailsMobile />
        )
      }
    </>
  )
}

export default AccountDetails